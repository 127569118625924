import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { IAssignee } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../core/store/hooks";
import { Userslist } from "./UserList.element";

interface TicketAssigneesProps {
  ticket: any;
  displayAllAssignees?: boolean;
  canEdit?: boolean;
  displayLabel?: boolean;
  onChange?: (assignee: Partial<IAssignee>[]) => void;
  onOpen?: (isOpen: boolean) => void;
}

export const TicketAssignees: FC<TicketAssigneesProps> = ({
  ticket,
  canEdit = true,
  displayAllAssignees = false,
  displayLabel = false,
  onChange = () => { },
  onOpen = () => { },
}) => {
  const { t } = useTranslation("ticket");
  const users = useAppSelector((state) => state.users.list);
  const [assigned, setAssigned] = useState<IUserMarina[]>([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (!ticket || !users) return;
    const assigned_users = users.filter((u) => {
      // return ticket.assignees.find((a: IAssignee) => a.agent === u.id) ? true : false;
      return ticket.assignees.find((a: IAssignee) => a.id === u.id) ? true : false;
    });
    setAssigned(assigned_users);
  }, [users, ticket]);

  useEffect(() => {
    onOpen(open);
  }, [open]);

  return (
    <Box
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
      className="flex items-center justify-center min-w-[175px]"
    >
      {/* {assigned.length === 0 && t("form.ref")} */}
      {/* {assigned.length === 0 && displayLabel && (
        <p className="text-sm m-0 p-0 block font-bold">{t("form.ref")}</p>
      )} */}
      {assigned.length > 0 && (
        <Userslist
          max={
            canEdit && !displayAllAssignees
              ? 4
              : assigned.length < 2
                ? 2
                : assigned.length
          }
          users={assigned}
        />
      )}
      <Menu
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setOpen(false);
        }}
        PaperProps={{
          elevation: 0,
          style: {
            maxHeight: 300,
          },
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <Box className="bg-fcomain py-2 font-bold text-white pl-4 -mt-2">
          {t("assignTo")}
        </Box>
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <FormControlLabel
              className="w-full"
              control={
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const currentlyChecked =
                      assigned.findIndex((a) => a.id === user.id) > -1;
                    const checked = e.target.checked;
                    let tmpAssigned: IUserMarina[] = [];
                    if (!currentlyChecked && checked) {
                      tmpAssigned = [...assigned, user];
                    } else if (!checked && currentlyChecked) {
                      tmpAssigned = assigned.filter((a) => a.id !== user.id);
                    }
                    // const assignee = tmpAssigned.map((u) => ({ agent: u.id }));
                    setAssigned(tmpAssigned);
                    onChange(tmpAssigned);
                  }}
                  checked={assigned.findIndex((a) => a.id === user.id) > -1}
                />
              }
              label={
                <Box className="flex gap-2 justify-center items-center">
                  <Avatar src={user.picture} />
                  <ListItemText primary={user.first_name} />
                </Box>
              }
            />
            {/* <Checkbox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const currentlyChecked =
                  assigned.findIndex((a) => a.id === user.id) > -1;
                const checked = e.target.checked;
                let tmpAssigned: UserMarina[] = [];
                if (!currentlyChecked && checked) {
                  tmpAssigned = [...assigned, user];
                } else if (!checked && currentlyChecked) {
                  tmpAssigned = assigned.filter((a) => a.id !== user.id);
                }
                const assignee = tmpAssigned.map((u) => ({ agent: u.id }));
                setAssigned(tmpAssigned);
                onChange(assignee);
              }}
              checked={assigned.findIndex((a) => a.id === user.id) > -1}
            />
            <Avatar src={user.picture} />
            <ListItemText primary={user.first_name} /> */}
          </MenuItem>
        ))}
      </Menu>
      {/* </Popper> */}
      {canEdit && (
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setOpen(!open);
            setAnchorEl(e.currentTarget);
          }}
          color="secondary"
          aria-label="update assignees list"
        >
          {assigned.length < 1 ? (
            <AddIcon color="secondary" />
          ) : (
            <Edit color="secondary" />
          )}
        </IconButton>
      )}
    </Box>
  );
};
