import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useModule } from "../../../core/services/Modules.service";
import { useAppSelector } from "../../../core/store/hooks";
import { TabMenu } from "../elements/TabMenu.element";

export const ModuleLayout: React.FC = ({ children }) => {
  const port = useAppSelector((state) => state.ports.current);
  const navigate = useNavigate();

  const { isModuleActivated, findFirstActivatedModule, currentModule, setCurrentModule } = useModule();
  const location = useLocation();



  const [loadingConfig, setLoadingConfig] = useState(true);

  useEffect(() => {

    if (port) {
      const module = location.pathname.substring(
        location.pathname.indexOf("modules/"),
      ).split("/")[1]
      console.log("use effect module", currentModule, module, findFirstActivatedModule());

      if (currentModule === module) return
      setCurrentModule(module)
      if (!isModuleActivated(module)) {
        console.log("module navigate to", findFirstActivatedModule(), "current:", currentModule, module);
        setCurrentModule(location.pathname.substring(
          findFirstActivatedModule().indexOf("modules/"),
        ).split("/")[1])
        navigate(findFirstActivatedModule());
      }
      setLoadingConfig(false);
    }
  }, [findFirstActivatedModule, isModuleActivated, location.pathname, navigate, port]);

  return (
    <Box className="module-layout w-full flex flex-col flex-grow relative">
      <TabMenu />
      {loadingConfig ? (
        <Box className="w-full flex flex-col flex-grow relative justify-center items-center">
          <CircularProgress color="secondary" size={40} />
        </Box>
      ) : (
        <>
          {children}
          <Outlet />
        </>
      )}
    </Box>
  );
};
