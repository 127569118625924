import { SvgIcon, SvgIconProps } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactNode } from "react";

type MenuReportingIconProps = {
  // SvgProps?: SvgIconProps,
  whiteSquare?: boolean
  icon?: string
  children?: ReactNode
}


export const MenuReportingIcon = (props: MenuReportingIconProps) => {
  const { icon, whiteSquare, children } = props
  return !whiteSquare ?
    (
      <Box
        component="img"
        sx={{
          width: 17
        }}
        alt="ticket icon"
        src={icon}
      />
    ) :
    (
      <Box component="div" className="flex rounded-sm bg-white h-6 w-6 items-center justify-center">
        {
          icon &&
          <Box
            component="img"
            sx={{
              width: 17
            }}
            alt="ticket icon"
            src={icon}
          />
        }

        {children}
      </Box>
    );
}

export default MenuReportingIcon;