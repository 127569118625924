import { useQAContext } from "../../contexts/QAContext";
import narwahlIMG from "./../../../assets/img/narwhal.png"

export const Narwhal = () => {
    const { toggleQAModal } = useQAContext();
    return <>
        <div
            style={{ zIndex: 99999 }}
            onClick={toggleQAModal}
            className="print:hidden bg-red-600 rounded-l-xl text-white cursor-pointer fixed top-0 right-0 p-1.5 mt-3"><img className="w-10" src={narwahlIMG} /></div>
    </>
}