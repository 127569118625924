import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getI18n, useTranslation } from "react-i18next";
import NewsIcon from "../../../../assets/img/menu/NewsIcon";
import { PageTitle } from "../../elements/PageTitle.element";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { useForm } from "react-hook-form";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "@ckeditor/ckeditor5-build-classic/build/translations/fr.js";
import "@ckeditor/ckeditor5-build-classic/build/translations/en-au.js";
import { ImageUploader } from "../../UX/ImageUploader.element";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useRef, useState } from "react";
import { AlertType, useAppContext } from "../../../contexts/AppContext";
import i18next from "i18next";
import { NewsTable } from "../../elements/news/NewsTable.element";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import {
  createNews,
  updateNews,
} from "../../../../core/store/actions/News.actions";
import {
  objectToFormData,
  resetAllFields,
  setAllFields,
} from "../../../../_helpers/form.helper";
import { setSelected } from "../../../../core/store/reducers/News.reducer";
import { UserPort } from "@wattsonelements/front-fdk/dist/models/User.models";
import { INewsTag } from "@wattsonelements/front-fdk/dist/models/News.models";
import FDK from "@wattsonelements/front-fdk";
import dayjs from "dayjs";
import i18n from "../../../../translations/initI18n";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const NewsCmsPage = () => {
  const { t } = useTranslation(["news", "common"]);
  const { setAlertMessage } = useAppContext();
  const selectedNews = useAppSelector((state) => state.news.selected);
  const loading = useAppSelector((state) => state.news.loading);
  const organization = useAppSelector((state) => state.users.profile?.organisation);
  const [tags, setTags] = useState<INewsTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedPorts, setSelectedPorts] = useState<string[]>([]);
  const ImgUploaded = useRef<any>(null)

  const dispatch = useAppDispatch();

  const newsForm = useForm({
    mode: "onChange"
  });
  newsForm.register("tags_json");
  newsForm.register("date_published", { required: true });
  newsForm.register("date_publication_end", { required: false });

  const tagChanged = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    let tmpValues = typeof value === "string" ? value.split(",") : value;
    setSelectedTags(tmpValues);
    let tmpTags: any[] = [];
    tmpValues.map((tmpValue: any) =>
      tmpTags.push(tags.find((tag: any) => tag.id === tmpValue))
    );
    newsForm.setValue("tags_json", tmpTags);
  };
  const portChanged = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    let tmpValues = typeof value === "string" ? value.split(",") : value;
    setSelectedPorts(tmpValues);
    // let tmpPorts: any[] = [];
    // tmpValues.map((tmpValue: any) =>
    //   tmpPorts.push(organization?.ports.find((port: any) => port.id === tmpValue))
    // );
    newsForm.setValue("ports", tmpValues);
  };

  const cancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSelected(null));
    // newsForm.reset()
    ImgUploaded.current?.reset()
    return false;
  };
  const submit = () => {
    if (newsForm.getValues("picture") === "") {
      setAlertMessage!({
        type: AlertType.ERROR,
        message: t("form.imgFormatError"),
      });
      return;
    }
    if (!Array.isArray(newsForm.getValues("tags_json")))
      newsForm.setValue("tags_json", []);
    const news = newsForm.getValues()
    const html = window.document.createElement("div")
    html.innerHTML = news.content
    const imgs = html.getElementsByTagName("img")
    for (let index = 0; index < imgs.length; index++) {
      const img = imgs[index]
      img.removeAttribute("height")
      img.removeAttribute("width")
    }
    news.content = html.innerHTML
    // hack to get div video 100% on the app boater
    news.content = news.content.replace(/<div data-oembed-url/, '<div style="width:100%" data-oembed-url')

    if (selectedNews) {

      dispatch(
        updateNews({
          id: selectedNews.id,
          news: objectToFormData(news),
        })
      ).then(
        (e) => {
          if (e.type === "news/update/rejected")
            setAlertMessage!({
              type: AlertType.ERROR,
              message: t("confirmError"),
            });
          else {
            setAlertMessage!({
              type: AlertType.SUCCESS,
              message: t("confirmEdition"),
            });
            // TODO do we exit the edition ?
            // dispatch(setSelected(null));
          }
        },
        (err) => {
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("confirmError"),
          });
        }
      );
    } else {
      dispatch(createNews(objectToFormData(news))).then(
        (e) => {
          if (e.type === "news/create/rejected")
            setAlertMessage!({
              type: AlertType.ERROR,
              message: t("confirmError"),
            });
          else {
            setAlertMessage!({
              type: AlertType.SUCCESS,
              message: t("confirmCreation"),
            });
            // resetAllFields(newsForm);
            newsForm.reset(news)
            // dispatch(setSelected(null))
          }
        },
        (err) => {
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("confirmError"),
          });
        }
      );
    }
  };

  const fetchTags = async () => {
    // try {
    FDK.Marina.NewsModule.getNewsTag().then(
      (datas) => {
        if (datas) {
          setTags(datas.data);
        }
      },
      (err) => {
        setAlertMessage!({
          type: AlertType.ERROR,
          message: "Error while getting the news tags",
        });
      }
    );
    // if (datas.data && datas.data.length > 0) setTags(datas.data);
    // } catch (e) {
    //   console.log(e);
    // }
  };

  useEffect(() => {
    if (tags.length < 1) {
      fetchTags();
    }
  }, []);

  useEffect(() => {
    if (selectedNews) {
      setAllFields(selectedNews, newsForm, { "tags": "tags_json" });
      setSelectedTags(selectedNews.tags.map((tag: any) => tag.id));
      newsForm.setValue("tags_json", [...selectedNews.tags]);
      setSelectedPorts(selectedNews.ports ? [...selectedNews.ports] : []);
      newsForm.setValue("ports", selectedNews.ports ? [...selectedNews.ports] : []);
    } else {
      resetAllFields(newsForm);
      setSelectedTags([]);
      setSelectedPorts([]);
      newsForm.setValue("tags_json", []);
    }
  }, [selectedNews]);

  return (
    <Box className="w-full h-full bg-white">
      <PageTitle
        title={t("title")}
        subtitle={t("subtitle")}
        icon={<NewsIcon />}
      />
      <Box className="flex items-start flex-wrap gap-x-4 w-full p-4">
        <Card className="w-1/3">
          <NewsTable />
        </Card>
        <Card
          component="form"
          onSubmit={newsForm.handleSubmit(submit)}
          noValidate
          className=" p-8 flex-1 flex flex-wrap flex-col"
        >
          <Box className="col-span flex items-center gap-x-2">
            <NewspaperIcon color="secondary" />
            <Typography color="secondary" component="h1" variant="h6">
              {t(selectedNews ? "form.update" : "form.formTitle")}
            </Typography>
            {selectedNews && (
              <Button
                // disabled={!newsForm.formState.isValid}
                onClick={cancel}
                size="small"
                variant="outlined"
                color="error"
              >
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <>{t("form.cancel")}</>
                  // <><CloseIcon /></>
                )}
              </Button>
            )}
          </Box>
          <Box className="flex flex-1 gap-x-6 flex-wrap">
            <Box className="flex-1">
              <TextField
                data-testid="news-title"
                margin="normal"
                required
                fullWidth
                helperText={`${newsForm.watch("subject", "").length}/50`}
                // helperText={!!pwdForm.formState.errors.subject && pwdForm.formState.errors.subject.message}
                error={!!newsForm.formState.errors.subject}
                inputProps={{ maxLength: 50 }}
                id="subject"
                label={t("form.title")}
                value={newsForm.watch("subject", "")}
                {...newsForm.register("subject", {
                  required: true,
                })}
                autoFocus
              />
              <TextField
                data-testid="news-catchphrase"
                margin="normal"
                required
                fullWidth
                helperText={
                  !!newsForm.formState.errors.catchphrase &&
                  newsForm.formState.errors.catchphrase.message
                }
                error={!!newsForm.formState.errors.catchphrase}
                id="catchphrase"
                label={t("form.catchphrase")}
                value={newsForm.watch("catchphrase", "")}
                {...newsForm.register("catchphrase", {
                  required: false,
                })}
              />
              <Box className="mt-5">
                <CKEditor
                  onChange={(event: any, editor: any) => {
                    let data = editor.getData()
                    newsForm.setValue("content", data);
                  }}
                  onReady={(editor: any) => {
                    editor.editing.view.change((writer: any) => {
                      writer.setStyle(
                        "height",
                        "350px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  editor={Editor}
                  config={{
                    allowedContent: false,
                    resize_enabled: true,
                    mediaEmbed: {
                      extraProviders: [
                        {
                          name: 'mp4',
                          url: [
                            /^(.*)?/,
                          ],
                          html: (match: any) => {
                            const url = match[1];
                            return (
                              '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                              '<video controls ' +
                              'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" >' +
                              `<source src="${url}" type="video/mp4">` +
                              '</video>' +
                              // `<iframe src="${url}"` +
                              // 'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                              // 'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                              // '</iframe>' +
                              '</div>'
                            );
                          }
                        }
                      ], previewsInData: true
                    },
                    forcePasteAsPlainText: true,
                    image: {
                      styles: {

                      },
                      insert: {
                        // This is the default configuration, you do not need to provide
                        // this configuration key if the list content and order reflects your needs.
                        integrations: ['url']
                      }
                    },
                    toolbar: {
                      // items: [
                      //   "bold",
                      //   "italic",
                      //   "underline",
                      //   "subscript",
                      //   "superscript",
                      //   "|",
                      //   "indent",
                      //   "outdent",
                      //   "|",
                      //   "blockQuote",
                      //   "|",
                      //   "alignment",
                      //   "|",
                      //   "insertImage",
                      //   "link",
                      //   "MediaEmbed",
                      //   "|",
                      //   "fontFamily",
                      //   "fontSize",
                      //   "fontColor",
                      //   "fontBackgroundColor",
                      //   "|",
                      //   "undo",
                      //   "redo",
                      // ],
                      shouldNotGroupWhenFull: true,
                    },
                    language: getI18n().language,
                  }}
                  data={newsForm.watch("content")}
                />
              </Box>
              <Box className="mt-5">
                <ImageUploader
                  hasCrop={newsForm.getValues("picture") && typeof newsForm.getValues("picture")[0] !== 'string'}
                  thumb={false}
                  multiple={false}
                  currentImg={newsForm.watch("picture", "")}
                  deleteButton={true}
                  name="picture"
                  preview={true}
                  form={newsForm}
                  ref={ImgUploaded}
                />
              </Box>
            </Box>
            <Box className="w-92">
              {(organization && organization.ports.length > 1) &&
                <Box className="w-full mt-4">
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel id="ports-tag">{t("form.ports")}</InputLabel>
                    <Select
                      data-testid="ports-tags"
                      labelId="ports-tag"
                      id="ports-tag-checkbox"
                      multiple
                      value={selectedPorts}
                      onChange={portChanged}
                      input={<OutlinedInput label={t("form.ports")} />}
                      renderValue={(selected) => (
                        <Box className="flex flex-wrap gap-1">
                          {selected.map((id: string) =>
                          (<Chip
                            sx={{ fontSize: 14 }}
                            key={id + "-chip-port"}
                            label={
                              organization?.ports.find((port: UserPort) => port.id === id)
                                ?.name
                            }
                          />)
                          )}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {organization?.ports.map((port: UserPort) => (
                        <MenuItem key={"port-" + port.id} value={port.id}>
                          <Checkbox checked={selectedPorts.indexOf(port.id) > -1} />
                          <ListItemText
                            primary={port.name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
              <p className="text-fcosecondary text-md font-bold mb-3 mt-5">
                {t("form.schedule")}
              </p>
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  ampm={t('dates.ampm', { ns: "common" })}
                  data-testid="news-date_published"
                  format={
                    t("dates.full", { ns: "common" }) +
                    " " +
                    t("dates.hour", { ns: "common" })
                  }
                  label={t("form.publishDate")}
                  slotProps={{ textField: { error: !!newsForm.formState.errors.date_published } }}
                  value={dayjs(newsForm.watch("date_published", ""))}
                  onChange={(newValue) => {
                    newValue !== null &&
                      newsForm.setValue("date_published", newValue.format());
                  }}
                />
                <p className="mt-8 text-fcosecondary text-md font-bold mb-3">
                  {t("form.scheduleEnd")}
                </p>
                <DateTimePicker
                  ampm={t('dates.ampm', { ns: "common" })}
                  data-testid="news-date_publication_end"
                  format={
                    t("dates.full", { ns: "common" }) +
                    " " +
                    t("dates.hour", { ns: "common" })
                  }
                  slotProps={{ textField: { error: !!newsForm.formState.errors.date_publication_end } }}
                  label={t("form.endDate")}
                  value={dayjs(newsForm.watch("date_publication_end", undefined))}
                  onChange={(newValue) => {
                    newValue !== null &&
                      newsForm.setValue(
                        "date_publication_end",
                        newValue.format()
                      );
                  }}
                />
              </LocalizationProvider>
              <Box className="w-full mt-5">
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="news-tag">{t("form.tags")}</InputLabel>
                  <Select
                    data-testid="news-tags"
                    labelId="news-tag"
                    id="news-tag-checkbox"
                    multiple
                    value={selectedTags}
                    onChange={tagChanged}
                    input={<OutlinedInput label={t("form.tags")} />}
                    renderValue={(selected) => (
                      <Box className="flex flex-wrap gap-1">
                        {selected.map((id: string) =>
                          i18next.language === "fr" ? (
                            <Chip
                              sx={{ fontSize: 11 }}
                              key={id + "-chip"}
                              label={
                                tags.find((tag: INewsTag) => tag.id === id)
                                  ?.label_fr
                              }
                            />
                          ) : (
                            <Chip
                              sx={{ fontSize: 11 }}
                              key={id + "-chip"}
                              label={
                                tags.find((tag: INewsTag) => tag.id === id)
                                  ?.label_en
                              }
                            />
                          )
                        )}
                      </Box>
                    )}
                    // to render text instead chips elements
                    // renderValue={(selected) =>
                    //   i18next.language === "fr" ?
                    //     selected.map((id: string) => tags.find((tag: INewsTag) => tag.id === id)?.label_fr + ", ") :
                    //     selected.map((id: string) => tags.find((tag: INewsTag) => tag.id === id)?.label_en + ", ")}
                    MenuProps={MenuProps}
                  >
                    {tags.map((tag: INewsTag) => (
                      <MenuItem key={"tag-" + tag.id} value={tag.id}>
                        <Checkbox checked={selectedTags.indexOf(tag.id) > -1} />
                        <ListItemText
                          primary={
                            i18next.language === "fr"
                              ? tag.label_fr
                              : tag.label_en
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <p className="mt-8 text-fcosecondary text-md font-bold mb-3">
                {t("form.settings")}
              </p>
              <FormControl>
                <Tooltip placement="left" arrow title={t("form.bookmarkedToolTip")}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          typeof newsForm.watch("bookmarked") === "boolean"
                            ? newsForm.watch("bookmarked")
                            : false
                        }
                        {...newsForm.register("bookmarked")}
                        color="secondary"
                      />
                    }
                    label={t("form.bookmarked")}
                  />
                </Tooltip>
                <Tooltip placement="left" arrow title={t("form.pushToolTip")}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          typeof newsForm.watch("requires_push") === "boolean"
                            ? newsForm.watch("requires_push")
                            : false
                        }
                        {...newsForm.register("requires_push")}
                        color="secondary"
                      />
                    }
                    label={t("form.push")}
                  />
                </Tooltip>
              </FormControl>
              <Box className="mt-8 flex gap-2">
                <Button
                  data-testid="news-save-btn"
                  // disabled={!newsForm.formState.isValid}
                  className="w-full"
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>{t(selectedNews ? "form.save" : "form.formTitle")}</>
                  )}
                </Button>
                {selectedNews && (
                  <Button
                    // disabled={!newsForm.formState.isValid}
                    onClick={cancel}
                    className="w-full"
                    size="large"
                    variant="outlined"
                    color="error"
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>{t("form.cancel")}</>
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
